import logo from '../logo.svg';

const strings = {
  home: {
    header: 'I am Brandon Manuel'
  }
};

const images = {
  home: {
    logo
  }
};

const config = {
  strings,
  images
};

export { config };
